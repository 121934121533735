import React, { lazy, Suspense } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import firebase from './Firebase'

const App = lazy(() => import('./App'))
const Signup = lazy(() => import('./components/Signup'))
const Login = lazy(() => import('./components/Login'))
const Dashboard = lazy(() => import('./components/Dashboard'))
const AboutUser = lazy(() => import('./components/AboutUser'))

const loading = () => (<div>loading...</div>)

const Routes: React.FC = (props) => {
    const [authenticated, setAuthenticated] = React.useState(JSON.parse((localStorage as any).getItem('authenticated')))
    const [user, setUser] = React.useState(JSON.parse((localStorage as any).getItem('currentUser')))

    React.useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if(user) {
                let userDetails = []
                userDetails.push(user.email, user.uid)
                // console.log(userDetails)
                localStorage.setItem('authenticated', JSON.stringify(true))
                setAuthenticated(true)
                localStorage.setItem('currentUser', JSON.stringify(userDetails))
                setUser(user)
            } else {
                localStorage.setItem('authenticated', JSON.stringify(false))
                setAuthenticated(false)
                localStorage.removeItem('currentUser')
                setUser(null)
            }
        })
    }, [])

    return (
        <Suspense fallback={loading}>
            <Router>
                <Switch>
                    <React.Fragment>
                        <Route exact path='/' component={(props: any) => <App {...props}/>} />
                        <Route exact path='/signup' component={(props: any) => <Signup {...props}/>} />
                        <Route exact path='/login' component={(props: any) => <Login {...props}/>} />
                        <PrivateRoute
                         exact 
                         path='/dashboard' 
                         authenticated={authenticated}
                         user={user} 
                         component={(props: any) => <Dashboard {...props} /> }/>
                         <PrivateRoute
                         exact 
                         path='/dashboard' 
                         authenticated={authenticated}
                         user={user} 
                         component={(props: any) => <AboutUser {...props} /> }/>
                    </React.Fragment>
                </Switch>
            </Router>
        </Suspense>
    )
}

export default Routes