import React from 'react'
import { Route, Redirect } from 'react-router-dom'

interface Props {
    authenticated: boolean
    path: string
    exact: boolean
    component: any
    user?: object
}

const PrivateRoute: React.FC<Props> =({
    component: Component,
    authenticated, 
    ...rest
}) => {
    return (
        <Route 
         {...rest}
         render={ props => 
            authenticated === true ? (
                <Component {...props} {...rest} />
            ) : (
                <Redirect to="/login" />
            )
         }
        />
    )
}

export default PrivateRoute